/* =============================================================================
   COLORS (settings/_colors.scss)
   ========================================================================== */

/*
 * Colors that are most represented in the design, every project must contain at
 * least one primary color.
 */

$color-primary: (
	primary: (
		default: #df4718,
		hover: #c53b11,
		focus: #ffc2af,
	)
);

/*
 * Colors that complement primary palette, every project must contain at least
 * one secondary color.
 */

$color-secondary: (
	secondary: (
		default: #eac513,
		hover: #d2ae00,
		focus: #f8ebae,
	)
);

/**
 * Colors used on notifications or interactive elements/forms with dynamic
 * states.
 */

$color-extra: (
	extra: (
		black: #000,
		white: #fff,
		gray-100: #ddd,
		gray-200: #b5b5b5,
	)
);

/**
 * Merge all color maps into one.
 */

$colors: map-merge(
	map-merge( $color-primary, $color-secondary ), $color-extra
);

/**
 * Colors used for base things such as base text color and links color.
 */

$color-base: color( extra, black );
$color-links: color( primary );
