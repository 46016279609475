/* =============================================================================
   TYPOGRAPHY (settings/_typography.scss)
   ========================================================================== */

/* Font Families
   ========================================================================== */

/**
 * Base font-family used on root elements.
 */

$font-family-base: 'deva-ideal', sans-serif;



/* Font Sizes
   ========================================================================== */

/**
 * Headings sizes used in elements and utilities layer.
 */

$font-sizes-headings: (
	h1: 3.125rem,
	h2: 2.75rem,
	h3: 2.375rem,
	h4: 1.875rem,
	h5: 1.25rem,
	h6: 1.125rem,
);

/**
 * Other sizes used in utilities layer for `font-size` utilities.
 */

$font-sizes-other: (
	xs: .75rem,
	sm: .875rem,
	base: 1rem,
);

/**
 * Merge all font size maps into one.
 */

$font-sizes: map-merge(
	$font-sizes-headings, $font-sizes-other
);



/* Line Heights
   ========================================================================== */

/**
 * Base line-height used on root elements.
 */

$line-height-headings: 1.3;
