/* =============================================================================
   BUTTON (settings/_button.scss)
   ========================================================================== */

/* General
   ========================================================================== */

$button-border-radius: 9999px;



/* Sizes
   ========================================================================== */

/**
 * Settings map used to generate size styles.
 */

$button-sizes: (
	xs: (
		font-size: font-size( base ),
		padding: .25rem 1rem,
	),
	sm: (
		font-size: font-size( md ),
		padding: .5rem 1.75rem,
	),
	md: (
		font-size: font-size( h4 ),
		padding: .75rem 2.5rem,
	),
	lg: (
		font-size: font-size( h3 ),
		padding: 1rem 3.125rem,
	),
);
