/* =============================================================================
   HEADER (components/_header.scss)
   ========================================================================== */

.c-header {
	padding-top: 1.5625rem;
	padding-bottom: 1.5625rem;
}



/* Inner
   ========================================================================== */

.c-header__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}



/* Logo
   ========================================================================== */

.c-header-logo {
	display: flex;
}

.c-header-logo__link {
	@extend .c-link-reset;
}

.c-header-logo__tag {
	display: none;
	color: color( extra, gray-200 );
	/* stylelint-disable-next-line font-weight-notation */
	font-weight: font-weight( medium );
	position: relative;
	bottom: -.4375rem;
	margin-bottom: 0;
	margin-left: 1.5625rem;

	@include media-breakpoint-up( md ) {
		display: block;
	}
}
