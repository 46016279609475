/* =============================================================================
   CTA (components/_cta.scss)
   ========================================================================== */

.c-cta {
	text-align: center;
	background-color: rgba( color( primary ), .1 );
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;

	@include media-breakpoint-up( md ) {
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}
}



/* Content
   ========================================================================== */

.c-cta__title {
	font-size: font-size( h4 );
	margin-bottom: 1.875rem;

	@include media-breakpoint-up( md ) {
		font-size: font-size( h3 );
	}
}
