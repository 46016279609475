/* =============================================================================
   STATISTICS (components/_statistics.scss)
   ========================================================================== */

.c-statistics {
	@include media-breakpoint-up( sm ) {
		display: flex;
		text-align: center;
	}
}



/* Item
   ========================================================================== */

.c-statistics__item {
	position: relative;
	flex-shrink: 1;
	flex-grow: 1;
	margin-bottom: .875rem;

	@include media-breakpoint-up( sm ) {
		margin-bottom: 0;
	}

	@include media-breakpoint-up( md ) {
		flex-basis: ( 100% / 3 );
		flex-shrink: 0;
		flex-grow: 0;
	}
}

.c-statistics__item + .c-statistics__item {
	&::before {
		@include media-breakpoint-up( sm ) {
			content: '';
			display: block;
			width: 1px;
			height: 3.125rem;
			background-color: color( extra, gray-100 );
			position: absolute;
			top: 50%;
			transform: translateY( -50% );
		}
	}
}



/* Content
   ========================================================================== */

.c-statistics__number,
.c-statistics__label {
	margin-bottom: 0;
}

.c-statistics__number {
	font-size: font-size( h4 );

	@include media-breakpoint-up( md ) {
		font-size: font-size( h3 );
	}

	@include media-breakpoint-up( xl ) {
		font-size: font-size( h1 );
	}
}

.c-statistics__label {
	font-size: font-size( h6 );

	@include media-breakpoint-up( md ) {
		font-size: font-size( h5 );
	}
}
