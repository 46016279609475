/* =============================================================================
   LINKS (components/_links.scss)
   ========================================================================== */

.c-link-reset {
	border-bottom: initial;

	&:hover {
		background-color: initial;
	}
}
