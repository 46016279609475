/* =============================================================================
   GALLERY (components/_gallery.scss)
   ========================================================================== */

.c-gallery {
	padding-top: 3.125rem;
	padding-bottom: 2.75rem;

	@include media-breakpoint-up( sm ) {
		padding-bottom: 1.875rem;
	}

	@include media-breakpoint-up( md ) {
		padding-top: 4.375rem;
		padding-bottom: 4.375rem;
	}

	@include media-breakpoint-up( xl ) {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
	}
}

.c-gallery__content {
	position: sticky;
	top: 2.5rem;

	@include media-breakpoint-up( sm ) {
		padding-right: 1.25rem;
	}
}

.c-gallery__title {
	font-size: font-size( h4 );
	margin-bottom: 3.125rem;

	@include media-breakpoint-up( lg ) {
		font-size: font-size( h3 );
	}

	@include media-breakpoint-up( xl ) {
		font-size: font-size( h2 );
	}
}



/* Grid
   ========================================================================== */

.c-gallery-grid {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up( md ) {
		margin-top: -5.625rem;
		margin-bottom: -7.5rem;
	}

	@include media-breakpoint-up( xl ) {
		margin-top: -7.5rem;
		margin-bottom: -9.375rem;
	}
}

.c-gallery-grid__item {
	width: calc( 50% - .5rem );
	flex-basis: calc( 50% - .5rem );
	margin-bottom: 1rem;

	@include media-breakpoint-up( sm ) {
		width: calc( 50% - ( #{$grid-gutter} / 2 ) );
		flex-basis: calc( 50% - ( #{$grid-gutter} / 2 ) );
		margin-bottom: $grid-gutter;
	}

	&:first-child {
		@include media-breakpoint-up( md ) {
			margin-left: calc( 50% + ( #{$grid-gutter} / 2 ) );
		}
	}

	&:nth-child( even ) {
		margin-left: 1rem;

		@include media-breakpoint-up( sm ) {
			margin-left: $grid-gutter;
		}

		@include media-breakpoint-up( md ) {
			margin-top: -8.75rem;
			margin-right: $grid-gutter;
			margin-left: 0;
		}
	}
}
