/* =============================================================================
   PARTNERS (components/_partners.scss)
   ========================================================================== */

.c-partners {
	text-align: center;
	background-color: rgba( color( secondary ), .1 );
	padding-top: 3.125rem;
	padding-bottom: 1.25rem;

	@include media-breakpoint-up( md ) {
		text-align: left;
		padding-top: 0;
		padding-bottom: 2.5rem;
	}
}

.c-partners__title {
	font-size: font-size( h4 );
	margin-bottom: 3.125rem;

	@include media-breakpoint-up( md ) {
		font-size: font-size( h3 );
		transform: translateY( -60% );
		margin-bottom: 1.875rem;
	}
}



/* Grid
   ========================================================================== */

.c-partners-grid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-right: ( $grid-gutter / 2 ) * -1;
	margin-left: ( $grid-gutter / 2 ) * -1;

	@include media-breakpoint-up( md ) {
		justify-content: initial;
		margin-right: -2.1875rem;
		margin-left: -2.1875rem;
	}
}

.c-partners-grid__item {
	padding-right: $grid-gutter / 2;
	margin-bottom: 2.5rem;
	padding-left: $grid-gutter / 2;

	@include media-breakpoint-up( md ) {
		width: 25%;
		flex: 0 0 25%;
		padding-right: 2.1875rem;
		padding-left: 2.1875rem;
	}

	@include media-breakpoint-up( lg ) {
		width: 20%;
		flex: 0 0 20%;
	}

	@include media-breakpoint-up( xl ) {
		width: 100% / 6;
		flex: 0 0 ( 100% / 6 );
	}
}

.c-partners-grid__link {
	@extend .c-link-reset;
	display: block;
}

.c-partners-grid__image {
	width: auto;
	max-height: 3.125rem;
}
