/* =============================================================================
   LINKS (elements/_links.scss)
   ========================================================================== */

a {
	border-bottom: .0625em solid;
	transition: background-color .2s;

	&:hover {
		background-color: rgba( $color-links, .08 );
	}
}
