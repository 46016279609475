/* =============================================================================
   HERO (components/_hero.scss)
   ========================================================================== */

.c-hero {
	padding-top: 1.875rem;
	padding-bottom: 3.125rem;

	@include media-breakpoint-up( sm ) {
		padding-top: 2.5rem;
		padding-bottom: 5rem;
	}

	@include media-breakpoint-up( md ) {
		padding-top: 8.75rem;
		padding-bottom: 13.125rem;
	}
}



/* Content
   ========================================================================== */

.c-hero__body {
	margin-bottom: 3.125rem;

	@include media-breakpoint-up( sm ) {
		margin-bottom: 2.5rem;
	}

	p {
		font-size: font-size( h6 );

		@include media-breakpoint-up( sm ) {
			font-size: font-size( h5 );
		}
	}
}

.c-hero__title {
	font-size: font-size( h3 );

	@include media-breakpoint-up( sm ) {
		font-size: font-size( h2 );
		margin-bottom: 2.5rem;
	}

	@include media-breakpoint-up( md ) {
		font-size: font-size( h1 );
	}
}



/* Image
   ========================================================================== */

.c-hero__image {
	@include media-breakpoint-up( sm ) {
		position: absolute;
		height: 100%;
		object-fit: cover;
		min-width: calc( 100% + ( ( 100vw - #{map-get( $breakpoints, sm )} ) / 2 ) );
	}

	@include media-breakpoint-up( md ) {
		min-width: calc( 100% + ( ( 100vw - #{map-get( $breakpoints, md )} ) / 2 ) );
	}

	@include media-breakpoint-up( lg ) {
		min-width: calc( 100% + ( ( 100vw - #{map-get( $breakpoints, lg )} ) / 2 ) );
	}

	@include media-breakpoint-up( xl ) {
		min-width: calc( 100% + ( ( 100vw - #{map-get( $breakpoints, xl )} ) / 2 ) );
	}
}
